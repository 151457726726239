<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/csm_Ersa-POWERFLUX-01-900x675_c819470ab9.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
              <br><br><br>
              <div class="row">
                <div class="col-2"></div>
                <div class="mt-1 col-6">
                  <h1 class="font-size-48 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                 Best selective process safety even with highest throughput.
                  </h2>
                </div>
                </div>
              </div>
            
              
            </div>
            <!-- end row -->
            <br /><br /><br />
            <div class="row">
              <div class="col-xl-6">
                <br /><br />
                <div class="mt-4">
                  <h1>Tech-Highlights POWERFLUX</h1>
                  <br />
                  <ul class="container">
                    <li>
                      <h4>
                     XL-version for 610 x 610 mm PCBs
                      </h4>
                    </li>
                    <br />
                    <li>
                      <h4>Reduction of flux consumption up to 95%</h4>
                    </li>
                      <br />
                    <li>
                      <h4>Short payback period</h4>
                    </li>
                     <li>
                      <h4>
                     Reduction of flux residue on the PCB
                      </h4>
                    </li>
                    <br />
                     <li>
                      <h4>
                    Reduced maintenance efforts on the fluxer unit and soldering system
                      </h4>
                    </li>
                    <br />
                     <li>
                      <h4>
                     Reduced efforts to maintain the solder masks or pallets
                      </h4>
                    </li>
                    <br />
                     <li>
                      <h4>
                     Increased service life for the solder masks or pallets
                      </h4>
                    </li>
                    <br />
                   
                    <br />
                  </ul>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/21csm_Ersa-Selective-SMARTFLOW-006-900x600_ae6f26d723.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br />

            <div class="row">
              <div class="col-8" style="margin-left: 50px">
                <h1><strong>Technical data</strong></h1>
              </div>
              <div class="col-3"></div>
            </div>
            <br />
            <b-tabs pills card vertical style="font-size: 18px">
              <b-tab active title="Dimensions">
                <div class="row" style="margin-bottom: 5px; margin-left: 10%">
                  <div class="col">
                    <h4>Length: 1.689 mm</h4>
                  </div>
                  <div class="col">
                    <h4>Height: aprox. 1.557 mm</h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col"><h4>Width: aprox. 1.080 mm</h4></div>
                  <div class="col"></div>
                </div>
              </b-tab>

              <b-tab title="Conveyor support">
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>Chain pin conveyor</h4>
                  </div>
                  <div class="col">
                    <h4>Maximum PCB build-up at the top up to 120 mm</h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col"><h4>PCB width: 60-508 mm</h4></div>
                  <div class="col">
                    <h4>Max. PCB bottom side clearance: 60 mm</h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col"><h4>PCB length: 60-508 mm</h4></div>
                  <div class="col">
                    <h4>Max. PCB weight: 8 kg</h4>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Fluxer ">
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>
                       Drop-Jet in different sizes
                    </h4>
                  </div>
                  <div class="col">
                    <h4><strong> </strong></h4>
                  </div>
                </div>

                
               
              </b-tab>
            </b-tabs>

          

            <br /><br />
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>